/* eslint-disable indent */
import styled, { css } from 'styled-components'

const FieldInfo = styled.div`
	display: flex;
	justify-content: space-between;

	transition: all 0.4s ease;

	${(props) =>
		props.helperText
			? css`
					min-height: 1.4rem;
					margin: 0.3rem 0 -0.6rem;
			  `
			: props.error
			? css`
					min-height: 0.8rem;
					margin: 0.3rem 1px -0.6rem;
			  `
			: css`
					min-height: 0;
					margin: 0;
			  `};

	&::before {
		content: '${(props) => props.helperText}';
		color: #959595;
		font-size: 0.8rem;
		margin-left: 2px;
		margin-top: -1px;
		flex: 1;
		${(props) =>
			props.helperText &&
			props.error &&
			css`
				margin-right: 1em;
			`}
	}
	&::after {
		/* 
    content: '${({ error }) => {
			if (!Array.isArray(error)) return typeof error === 'string' ? error : ''
			if (error.length === 1) return `1 campo inválido`
			if (error.length > 1) return `${error.length} campos inválidos`
			return ''
		}}';
    */
		${({ error }) => {
			if (!Array.isArray(error))
				return typeof error === 'string'
					? `content: "${error}";`
					: 'content: "";'
			if (error.length === 1) return `content: "1 campo inválido";`
			if (error.length > 1)
				return `content: "${error.length} campos inválidos";`
			return 'content: "";'
		}}
		color: #d22e2e;
		text-align: right;
		font-size: 0.6rem;
		font-style: italic;
		margin-right: 1px;
	}
`

export default FieldInfo
