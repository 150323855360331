/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import setFieldData from 'final-form-set-field-data'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import { isEmpty, update } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'

import {
	AttachmentField,
	CpfField,
	DateTextField,
	FieldGroup,
	FieldSection,
	FieldValuesSpy,
	FieldWrapper,
	FormBody,
	FormError,
	FormFooter,
	FormSection,
	FormWrapper,
	Loading,
	RadioField,
	RadioFieldGroup,
	SelectField,
	Text,
	TextField,
	CheckBoxField,
	FormRow,
	Button,
} from '~/components'
import {
	CITIES_BY_UF,
	COUNTRIES,
	ESCOLARIDADES,
	FORMACOES,
	STATUS_DOCUMENTO,
	TIPOS_INSTITUICAO,
	UFS,
	RNE_CLASSIFICACAO,
} from '~/config/constants'
import {
	formatCEP,
	formatGenericDoc,
	formatOnlyNumbers,
} from '~/helpers/formatters'
import history from '~/helpers/history'
import { cep, dateAfter, dateBefore, minLength } from '~/helpers/validators'
import usePBVForm from '~/hooks/usePBVForm'
import MainLayout from '~/layouts/mainLayout'
import FormWarning from '~/components/formWarning'

import { path as homePath } from '../homePage'
import ErrorPage from '../errorPage'
import { DocWrapper } from './styles'

export const title = 'Documentos não aprovados'
export const path = '/documentos-nao-aprovados'

// insere alterado: true no documento quando o anexo é alterado
const attachmentChangeHandler = (form) =>
	createDecorator({
		field: /\.id[^.]*$/,
		updates: async (value, name, allValues) => {
			const fieldState = form.getFieldState(name)

			if (!fieldState?.modified || fieldState?.data?.type !== 'attachment') {
				return {}
			}
			const docPath = name.replace(/\.id[^.]*$/, '')
			update(allValues, docPath, (doc) => ({ ...doc, alterado: true }))
			return allValues
		},
	})(form)

const attachmentFields = [
	'dadosPessoais.certidaoCasamento.idAnexo',
	'dadosPessoais.rne.idAnexo',
	'dadosPessoais.passaporte.idAnexo',
	'dadosPessoais.rg.idAnexoFrente',
	'dadosPessoais.rg.idAnexoVerso',
	'dadosPessoais.cpf.idAnexo',
	'dadosPessoais.ctps.idAnexoPaginaFoto',
	'dadosPessoais.ctps.idAnexoPaginaQualificacao',
	'dadosPessoais.ctps.idAnexoPaginaUltimoContrato',
	'dadosPessoais.ctps.idAnexoPaginaEmBranco',
	'dadosPessoais.ctps.idAnexoPaginaUltimoContratoDigital',
	'dadosPessoais.cnh.idAnexoFrente',
	'dadosPessoais.cnh.idAnexoVerso',
	'dadosPessoais.tituloEleitor.idAnexoFrente',
	'dadosPessoais.tituloEleitor.idAnexoVerso',
	'dadosPessoais.certificadoReservista.idAnexoFrente',
	'dadosPessoais.certificadoReservista.idAnexoVerso',
	'enderecoContato.comprovanteResidencia.idAnexo',
	'formacao.comprovanteEscolaridade.idAnexo',
	'[]familia.docs[].certidaoNascimento.idAnexo', // [] no início indica para o converter que é um campo com array e deve ser iterado
	'[]familia.docs[].cadernetaVacinacao.idAnexoIdentificacao',
	'[]familia.docs[].cadernetaVacinacao.idAnexoQuadroVacinal',
	'aso.idAnexo',
]

const dateFields = [
	'dadosPessoais.rne.dataExpedicao',
	'dadosPessoais.passaporte.dataChegadaBrasil',
	'dadosPessoais.rg.dataEmissao',
	'dadosPessoais.cnh.dataValidade',
	'dadosPessoais.certificadoReservista.dataDispensa',
	'dadosPessoais.ctps.dataEmissao',
	'[]familia.docs[].certidaoNascimento.dataNascimento',
]

const monthFields = [
	'formacao.comprovanteEscolaridade.inicio',
	'formacao.comprovanteEscolaridade.conclusao',
]

const hasAnyValueRecursive = (obj) =>
	obj &&
	Object.values(obj).some(
		(value) => !(value === null || value === undefined || isEmpty(value)),
	)

const DocReviewPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	// eslint-disable-next-line no-unused-vars
	const [city, setCity] = useState('')

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm('revisaoDocs', {
			attachmentFields,
			dateFields,
			monthFields,
		})

	const handleGoBack = () => history.push(homePath)

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) await submitForm(values)
			handleGoBack()
		} catch (err) {
			console.log(err)
		}
	}

	// remover erros forçados (dos fieldGroups) quando algum campo de anexo interno for modificado
	const handleForcedErrorUnset = (fieldsStates) => {
		const attachmentFieldsStates = fieldsStates.filter(
			(fs) => fs.data?.type === 'attachment',
		)

		return (
			attachmentFieldsStates.length > 0 &&
			attachmentFieldsStates.filter((fs) => fs.modified).length ===
				attachmentFieldsStates.length
		)
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)
	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			{editingDisabled ? (
				<FormWrapper as="div">
					<FormBody>
						<Text dark>
							<p>
								Neste momento, você não possui nenhum documento não aprovado
							</p>
						</Text>

						<Button primary large onClick={handleGoBack}>
							Voltar
						</Button>
					</FormBody>
				</FormWrapper>
			) : (
				<Form
					onSubmit={handleSubmit}
					initialValues={initialValues}
					subscription={{
						submitting: true,
						submitSucceeded: true,
						pristine: true,
						submitError: true,
					}}
					mutators={{ setFieldData, ...arrayMutators }}
					decorators={[attachmentChangeHandler]}
				>
					{({ handleSubmit, submitError, submitting, form }) => (
						<FormWrapper onSubmit={handleSubmit}>
							<FormBody>
								<Text dark>
									<p>
										Agora vamos tratar e corrigir todos os dados e/ou documentos
										que apresentam alguma inconsistência.
									</p>
								</Text>

								{/* ----- DADOS PESSOAIS ----- */}
								{hasAnyValueRecursive(initialValues?.dadosPessoais) && (
									<FormSection label="Dados pessoais">
										{/* CERTIDÃO CASAMENTO */}
										{hasAnyValueRecursive(
											initialValues.dadosPessoais?.certidaoCasamento,
										) && (
											<FieldValuesSpy name="dadosPessoais.certidaoCasamento.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.certidaoCasamento"
															label="Certidão de casamento"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.certidaoCasamento.idAnexo"
																	type="certidao_casamento"
																	label="Foto da Certidão"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* RNE */}
										{hasAnyValueRecursive(initialValues.dadosPessoais?.rne) && (
											<FieldValuesSpy name="dadosPessoais.rne.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.rne"
															label="RNE"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<SelectField
																	name="dadosPessoais.rne.classificacao"
																	label="Classificação"
																	options={RNE_CLASSIFICACAO}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.rne.numero"
																	label="RNE"
																	maxLength="20"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.rne.orgaoEmissor"
																	label="Orgão emissor"
																	maxLength="20"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<SelectField
																	name="dadosPessoais.rne.uf"
																	label="UF"
																	options={UFS}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<DateTextField
																	name="dadosPessoais.rne.dataExpedicao"
																	label="Data de expedição"
																	validators={[dateBefore()]}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.rne.idAnexo"
																	type="rne"
																	label="Foto do RNE"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* PASSAPORTE */}

										{hasAnyValueRecursive(
											initialValues.dadosPessoais?.passaporte,
										) && (
											<FieldValuesSpy name="dadosPessoais.passaporte.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.passaporte"
															label="Passaporte"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.passaporte.numero"
																	label="Número"
																	maxLength="10"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<DateTextField
																	name="dadosPessoais.passaporte.dataChegadaBrasil"
																	label="Data de chegada ao Brasil"
																	validators={[dateBefore()]}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.passaporte.numeroSerieVisto"
																	label="Número do visto"
																	maxLength="20"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.passaporte.idAnexo"
																	type="passaporte"
																	label="Página de Identificação"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* RG */}

										{hasAnyValueRecursive(initialValues.dadosPessoais?.rg) && (
											<FieldValuesSpy name="dadosPessoais.rg.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.rg"
															label="RG"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.rg.numero"
																	label="Número"
																	maxLength="15"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.rg.orgao"
																	label="Órgão"
																	maxLength="20"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<SelectField
																	name="dadosPessoais.rg.uf"
																	label="UF de emissão"
																	options={UFS}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<DateTextField
																	name="dadosPessoais.rg.dataEmissao"
																	label="Data de expedição"
																	validators={[dateBefore()]}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.rg.idAnexoFrente"
																	type="rg_frente"
																	label="Frente do RG"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.rg.idAnexoVerso"
																	type="rg_verso"
																	label="Verso do RG"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* CPF */}

										{hasAnyValueRecursive(initialValues.dadosPessoais?.cpf) && (
											<FieldValuesSpy name="dadosPessoais.cpf.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.cpf"
															label="CPF"
															mainField={['cpf.idAnexo', 'cpf.constaNoRG']}
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<CpfField
																	name="dadosPessoais.cpf.numero"
																	label="Número"
																	disabled
																/>
															</FieldWrapper>

															<FieldWrapper>
																<CheckBoxField
																	name="dadosPessoais.cpf.constaNoRG"
																	label="O número do CPF já consta no RG"
																/>
															</FieldWrapper>

															<FieldValuesSpy name="dadosPessoais.cpf.constaNoRG">
																{(constaNoRG) => (
																	<FieldWrapper shouldRender={!constaNoRG}>
																		<AttachmentField
																			noDelete
																			forceErrorUntilChange={
																				STATUS_DOCUMENTO[motivoReprovado]
																			}
																			name="dadosPessoais.cpf.idAnexo"
																			type="cpf"
																			label="Foto do CPF"
																		/>
																	</FieldWrapper>
																)}
															</FieldValuesSpy>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* CTPS */}

										{hasAnyValueRecursive(
											initialValues.dadosPessoais?.ctps,
										) && (
											<FieldValuesSpy name="dadosPessoais.ctps.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.ctps"
															label="Carteira de Trabalho"
															mainField="ctps.numero"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<RadioFieldGroup
																	inline
																	label="Sua carteira de trabalho é Física ou Digital?"
																	helperText="Se tiver apenas no aplicativo, marque a opção Digital"
																>
																	<RadioField
																		name="dadosPessoais.ctps.flagDigital"
																		value={false}
																		label="Física"
																		isBoolean
																		disabled
																	/>
																	<RadioField
																		name="dadosPessoais.ctps.flagDigital"
																		value
																		label="Digital"
																		isBoolean
																		disabled
																	/>
																</RadioFieldGroup>
															</FieldWrapper>

															<FieldValuesSpy name="dadosPessoais.ctps">
																{(ctps) =>
																	ctps?.flagDigital === false ? (
																		// carteira física
																		<>
																			<FieldWrapper>
																				<TextField
																					name="dadosPessoais.ctps.numero"
																					label="Número"
																					maxLength="20"
																					formatter={formatOnlyNumbers}
																					disabled
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<TextField
																					name="dadosPessoais.ctps.numeroSerie"
																					label="Número de série"
																					maxLength="10"
																					formatter={formatOnlyNumbers}
																					disabled
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<DateTextField
																					name="dadosPessoais.ctps.dataEmissao"
																					label="Data de emissão"
																					validators={[dateBefore()]}
																					disabled
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<SelectField
																					name="dadosPessoais.ctps.uf"
																					label="UF de emissão"
																					options={UFS}
																					disabled
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name="dadosPessoais.ctps.idAnexoPaginaFoto"
																					type="ctps_foto"
																					label="Página de foto"
																					helperText="A página que possui a foto e a impressão digital"
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name="dadosPessoais.ctps.idAnexoPaginaQualificacao"
																					type="ctps_info"
																					label="Página de qualificação civil"
																					helperText="A página que possui as informações pessoais"
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name="dadosPessoais.ctps.idAnexoPaginaUltimoContrato"
																					type="ctps_ultimo_contrato"
																					label="Última página de contrato"
																					helperText="Última página que possui contrato de trabalho, com a devida baixa"
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name="dadosPessoais.ctps.idAnexoPaginaEmBranco"
																					type="ctps_pagina_branco"
																					label="Página em branco após contrato"
																					helperText="Página necessária para provar que não tem registros após o último contrato"
																				/>
																			</FieldWrapper>
																		</>
																	) : ctps?.flagDigital === true ? (
																		// carteira digital
																		<>
																			<FieldWrapper>
																				<DateTextField
																					name="dadosPessoais.ctps.dataEmissao"
																					label="Data de emissão"
																					validators={[dateBefore()]}
																					disabled
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<SelectField
																					name="dadosPessoais.ctps.uf"
																					label="UF de emissão"
																					options={UFS}
																					disabled
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<RadioFieldGroup
																					inline
																					label="Possui emprego anterior?"
																				>
																					<RadioField
																						name="dadosPessoais.ctps.flagEmpregoAnterior"
																						value
																						label="Sim"
																						isBoolean
																						disabled
																					/>
																					<RadioField
																						name="dadosPessoais.ctps.flagEmpregoAnterior"
																						value={false}
																						label="Não"
																						isBoolean
																						disabled
																					/>
																				</RadioFieldGroup>
																			</FieldWrapper>

																			<FieldWrapper
																				shouldRender={ctps?.flagEmpregoAnterior}
																			>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name="dadosPessoais.ctps.idAnexoPaginaUltimoContratoDigital"
																					type="ctps_ultimo_contrato_digital"
																					label="Último contrato de trabalho"
																					helperText="Captura de tela do último contrato de trabalho com a devida baixa"
																				/>
																			</FieldWrapper>
																		</>
																	) : undefined
																}
															</FieldValuesSpy>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* CARTEIRA HABILITAÇÃO */}

										{hasAnyValueRecursive(initialValues.dadosPessoais?.cnh) && (
											<FieldValuesSpy name="dadosPessoais.cnh.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.cnh"
															label="Carteira Nacional de Habilitação"
															helperText="Opcional"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.cnh.numero"
																	label="Número de Registro"
																	maxLength="20"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<SelectField
																	name="dadosPessoais.cnh.uf"
																	label="UF de emissão"
																	options={UFS}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<DateTextField
																	name="dadosPessoais.cnh.dataValidade"
																	label="Data de validade"
																	validators={[dateAfter()]}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.cnh.categoria"
																	label="Categoria"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.cnh.idAnexoFrente"
																	type="cnh_frente"
																	label="Frente da CNH"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.cnh.idAnexoVerso"
																	type="cnh_verso"
																	label="Verso da CNH"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* TÍTULO DE ELEITOR */}

										{hasAnyValueRecursive(
											initialValues.dadosPessoais?.tituloEleitor,
										) && (
											<FieldValuesSpy name="dadosPessoais.tituloEleitor.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.tituloEleitor"
															label="Título de eleitor"
															helperText="Opcional e apenas para maiores de 18 anos"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.tituloEleitor.inscricao"
																	label="Inscrição"
																	maxLength="20"
																	formatter={formatOnlyNumbers}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.tituloEleitor.zona"
																	label="Zona"
																	maxLength="10"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.tituloEleitor.secao"
																	label="Seção"
																	maxLength="10"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.tituloEleitor.idAnexoFrente"
																	type="titulo_eleitor_frente"
																	label="Frente do Título de eleitor"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.tituloEleitor.idAnexoVerso"
																	type="titulo_eleitor_verso"
																	label="Verso do Título de eleitor"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}

										{/* CERTIFICADO DE RESERVISTA */}

										{hasAnyValueRecursive(
											initialValues.dadosPessoais?.certificadoReservista,
										) && (
											<FieldValuesSpy name="dadosPessoais.certificadoReservista.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="dadosPessoais.certificadoReservista"
															label="Certificado de Reservista"
															helperText="Obrigatório para homens entre 18 e 45 anos"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<TextField
																	name="dadosPessoais.certificadoReservista.numeroCertificado"
																	label="Número"
																	maxLength="20"
																	formatter={formatGenericDoc}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<DateTextField
																	name="dadosPessoais.certificadoReservista.dataDispensa"
																	label="Data de dispensa"
																	validators={[dateBefore()]}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<SelectField
																	name="dadosPessoais.certificadoReservista.uf"
																	label="UF de emissão"
																	options={UFS}
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.certificadoReservista.idAnexoFrente"
																	type="certificado_reservista_frente"
																	label="Frente do Certificado de Reservista"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="dadosPessoais.certificadoReservista.idAnexoVerso"
																	type="certificado_reservista_verso"
																	label="Verso do Certificado de Reservista"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}
									</FormSection>
								)}

								{/* ----- ENDEREÇO E CONTATO ----- */}
								{hasAnyValueRecursive(initialValues?.enderecoContato) && (
									<FormSection label="Endereço e Contato">
										{/* COMPROVANTE DE RESIDÊNCIA */}
										{hasAnyValueRecursive(
											initialValues.enderecoContato?.comprovanteResidencia,
										) && (
											<FieldValuesSpy name="enderecoContato.comprovanteResidencia.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="enderecoContato.comprovanteResidencia"
															label="Comprovante de residência"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															{/* PAÍS */}
															<FieldWrapper>
																<SelectField
																	name="enderecoContato.comprovanteResidencia.pais"
																	label="País"
																	showSearchByDefault
																	options={COUNTRIES}
																	autoComplete="section-address country"
																/>
															</FieldWrapper>

															<FieldValuesSpy
																names={[
																	'enderecoContato.comprovanteResidencia.pais',
																	'enderecoContato.comprovanteResidencia.estado',
																	'enderecoContato.comprovanteResidencia.cidade',
																]}
															>
																{(values) => {
																	const pais =
																		values[
																			'enderecoContato.comprovanteResidencia.pais'
																		]
																	const estado =
																		values[
																			'enderecoContato.comprovanteResidencia.estado'
																		]
																	const cidade =
																		values[
																			'enderecoContato.comprovanteResidencia.cidade'
																		]
																	const isBrazil = pais === 'BR' || !pais // true se pais for brasil ou undefined

																	return (
																		<>
																			{/* CEP */}
																			<FieldWrapper>
																				<TextField
																					name="enderecoContato.comprovanteResidencia.cep"
																					label={
																						isBrazil ? 'CEP' : 'Código Postal'
																					}
																					validators={[cep]}
																					formatter={formatCEP}
																					parser={(value) =>
																						value.replace(/\D/g, '').slice(0, 8)
																					}
																					autoComplete="section-address postal-code"
																				/>
																			</FieldWrapper>

																			<FormRow
																				template={[0.24, 'auto']}
																				disableTemplate={!isBrazil}
																			>
																				{/* UF */}
																				<FieldWrapper>
																					{isBrazil ? (
																						<SelectField
																							name="enderecoContato.comprovanteResidencia.estado"
																							label="UF"
																							value={
																								!UFS.includes(estado)
																									? form.change('estado', '')
																									: estado
																							}
																							options={UFS}
																							autoComplete="section-address address-level1"
																						/>
																					) : (
																						<TextField
																							name="enderecoContato.comprovanteResidencia.estado"
																							label="Estado/Distrito/Província"
																							autoComplete="section-address address-level1"
																						/>
																					)}
																				</FieldWrapper>

																				{/* MUNICÍPIO */}
																				<FieldWrapper>
																					{isBrazil ? (
																						<SelectField
																							showSearchByDefault
																							name="enderecoContato.comprovanteResidencia.cidade"
																							label="Município"
																							options={CITIES_BY_UF[estado]}
																							value={city || cidade}
																							autoComplete="section-address address-level2"
																							disabled={
																								!pais ||
																								!estado ||
																								!UFS.includes(estado)
																							}
																						/>
																					) : (
																						<TextField
																							name="enderecoContato.comprovanteResidencia.cidade"
																							label="Cidade/Município"
																							autoComplete="section-address address-level2"
																						/>
																					)}
																				</FieldWrapper>
																			</FormRow>
																		</>
																	)
																}}
															</FieldValuesSpy>

															{/* BAIRRO */}
															<FieldWrapper>
																<TextField
																	name="enderecoContato.comprovanteResidencia.bairro"
																	label="Bairro"
																	maxLength="30"
																	autoComplete="section-address address-level3"
																/>
															</FieldWrapper>

															{/* LOGRADOURO */}
															<FieldWrapper>
																<TextField
																	name="enderecoContato.comprovanteResidencia.logradouro"
																	label="Logradouro"
																	maxLength="100"
																	autoComplete="section-address address-line1"
																/>
															</FieldWrapper>

															<FormRow template={[0.24, 'auto']}>
																{/* NÚMERO */}
																<FieldWrapper>
																	<TextField
																		name="enderecoContato.comprovanteResidencia.numero"
																		label="Nº"
																		maxLength="10"
																		autoComplete="section-address street-number"
																	/>
																</FieldWrapper>

																{/* COMPLEMENTO */}
																<FieldWrapper>
																	<TextField
																		name="enderecoContato.comprovanteResidencia.complemento"
																		label="Complemento"
																		maxLength="10"
																		autoComplete="section-address address-line2"
																	/>
																</FieldWrapper>
															</FormRow>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="enderecoContato.comprovanteResidencia.idAnexo"
																	type="comprovante_residencia"
																	label="Foto do Comprovante de residência"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}
									</FormSection>
								)}

								{/* ----- FORMAÇÃO ----- */}
								{hasAnyValueRecursive(initialValues?.formacao) && (
									<FormSection label="Formação">
										{/* COMPROVANTE DE ESCOLARIDADE */}
										{hasAnyValueRecursive(
											initialValues.formacao?.comprovanteEscolaridade,
										) && (
											<FieldValuesSpy name="formacao.comprovanteEscolaridade.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="formacao.comprovanteEscolaridade"
															label="Comprovante de escolaridade"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															{/* ESCOLARIDADE */}
															<FieldWrapper>
																<SelectField
																	name="formacao.comprovanteEscolaridade.escolaridade"
																	label="Escolaridade"
																	options={ESCOLARIDADES}
																/>
															</FieldWrapper>

															{/* INSTITUIÇÃO */}
															<FieldWrapper>
																<TextField
																	name="formacao.comprovanteEscolaridade.instituicao"
																	label="Nome da Instituição"
																	validators={[minLength(3)]}
																/>
															</FieldWrapper>

															<FieldValuesSpy name="formacao.comprovanteEscolaridade.escolaridade">
																{(escolaridade) => {
																	const escolaridadeIndex =
																		ESCOLARIDADES.findIndex(
																			(e) => e[0] === escolaridade,
																		)
																	return (
																		<>
																			{/* TIPO DE INSTITUIÇÃO */}
																			<FieldWrapper
																				shouldRender={!(escolaridadeIndex < 2)}
																			>
																				<SelectField
																					name="formacao.comprovanteEscolaridade.tipoInstituicao"
																					label="Tipo de Instituição"
																					options={
																						escolaridadeIndex < 4
																							? TIPOS_INSTITUICAO.slice(0, 5)
																							: TIPOS_INSTITUICAO.slice(5)
																					}
																					disabled={!escolaridade}
																				/>
																			</FieldWrapper>

																			{/* CURSO/FORMAÇÃO */}
																			<FieldWrapper
																				shouldRender={!(escolaridadeIndex < 2)}
																			>
																				<SelectField
																					name="formacao.comprovanteEscolaridade.formacao"
																					label="Formação"
																					showSearchByDefault
																					sort
																					options={
																						escolaridadeIndex < 4
																							? FORMACOES.slice(0, 13)
																							: FORMACOES.slice(12, -1)
																					}
																					otherOption={[FORMACOES.slice(-1)]}
																				/>
																			</FieldWrapper>
																		</>
																	)
																}}
															</FieldValuesSpy>

															{/* PAÍS */}
															<FieldWrapper>
																<SelectField
																	name="formacao.comprovanteEscolaridade.pais"
																	label="País"
																	showSearchByDefault
																	options={COUNTRIES}
																/>
															</FieldWrapper>

															{/* INÍCIO */}
															<FieldWrapper>
																<DateTextField
																	hideDays
																	name="formacao.comprovanteEscolaridade.inicio"
																	label="Início"
																	placeholder="mês/ano (ex.: 04/2010)"
																	validators={[dateBefore('MM/yyyy')]}
																/>
															</FieldWrapper>

															{/* CONCLUSÃO */}
															<FieldWrapper>
																<DateTextField
																	hideDays
																	name="formacao.comprovanteEscolaridade.conclusao"
																	label="Conclusão"
																	placeholder="mês/ano (ex.: 04/2010)"
																/>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="formacao.comprovanteEscolaridade.idAnexo"
																	type="comprovante_escolaridade"
																	label="Comprovante de escolaridade"
																	helperText="Diploma ou declaração de matrícula"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}
									</FormSection>
								)}

								{/* ----- FAMÍLIA ----- */}
								<FieldArray name="familia.docs">
									{({ fields }) =>
										fields.length > 0 && (
											<FormSection label="Família">
												{/* debug: */}
												{/* <pre>{JSON.stringify(fields, undefined, 1)}</pre> */}
												{fields.map((f, index) => (
													<FieldSection
														key={fields.value[index].uuid}
														label={fields.value[index].nome}
													>
														{/* CERTIDÃO NASCIMENTO */}
														{hasAnyValueRecursive(
															fields.value[index].certidaoNascimento,
														) && (
															<FieldValuesSpy
																name={`${f}.certidaoNascimento.motivoReprovado`}
															>
																{(motivoReprovado) => (
																	<DocWrapper>
																		<FieldGroup
																			name={`familia.docs[${index}].certidaoNascimento`}
																			label="Certidão de Nascimento"
																			forceErrorOnMount
																			unsetForcedErrorOn={
																				handleForcedErrorUnset
																			}
																		>
																			<FieldWrapper>
																				<FormWarning highlight>
																					<Text>
																						<strong>
																							{
																								STATUS_DOCUMENTO[
																									motivoReprovado
																								]
																							}
																						</strong>
																						<p>
																							Verifique abaixo seus dados e
																							envie novamente o arquivo do
																							comprovante
																						</p>
																					</Text>
																				</FormWarning>
																			</FieldWrapper>

																			{/* DATA DE NASCIMENTO */}
																			<FieldWrapper>
																				<DateTextField
																					name={`${f}.certidaoNascimento.dataNascimento`}
																					label="Data de Nascimento"
																					validators={[dateBefore()]}
																					disabled
																				/>
																			</FieldWrapper>

																			<FieldWrapper>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name={`${f}.certidaoNascimento.idAnexo`}
																					type={`certidao_nascimento/${fields.value[index].uuid}`}
																					label="Foto da Certidão"
																				/>
																			</FieldWrapper>
																		</FieldGroup>
																		<FormWarning center>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</FormWarning>
																	</DocWrapper>
																)}
															</FieldValuesSpy>
														)}
														{/* CADERNETA VACINAÇÃO */}
														{hasAnyValueRecursive(
															fields.value[index].cadernetaVacinacao,
														) && (
															<FieldValuesSpy
																name={`${f}.cadernetaVacinacao.motivoReprovado`}
															>
																{(motivoReprovado) => (
																	<DocWrapper>
																		<FieldGroup
																			name={`familia.docs[${index}].cadernetaVacinacao`}
																			label="Caderneta de vacinação"
																			forceErrorOnMount
																			unsetForcedErrorOn={
																				handleForcedErrorUnset
																			}
																		>
																			<FieldWrapper>
																				<FormWarning highlight>
																					<Text>
																						<strong>
																							{
																								STATUS_DOCUMENTO[
																									motivoReprovado
																								]
																							}
																						</strong>
																						<p>
																							Verifique abaixo seus dados e
																							envie novamente o arquivo do
																							comprovante
																						</p>
																					</Text>
																				</FormWarning>
																			</FieldWrapper>

																			<FieldWrapper>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name={`${f}.cadernetaVacinacao.idAnexoIdentificacao`}
																					type={`caderneta_vacinacao_identificacao/${fields.value[index].uuid}`}
																					label="Página de identificação"
																				/>
																			</FieldWrapper>
																			<FieldWrapper>
																				<AttachmentField
																					noDelete
																					forceErrorUntilChange={
																						STATUS_DOCUMENTO[motivoReprovado]
																					}
																					name={`${f}.cadernetaVacinacao.idAnexoQuadroVacinal`}
																					type={`caderneta_vacinacao_quadro_vacinal/${fields.value[index].uuid}`}
																					label="Quadro Vacinal"
																				/>
																			</FieldWrapper>
																		</FieldGroup>
																		<FormWarning center>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</FormWarning>
																	</DocWrapper>
																)}
															</FieldValuesSpy>
														)}
													</FieldSection>
												))}
											</FormSection>
										)
									}
								</FieldArray>

								{/* ----- ASO ----- */}
								{hasAnyValueRecursive(initialValues?.aso) && (
									<FormSection label="Exame Ocupacional">
										{/* COMPROVANTE DE ESCOLARIDADE */}
										{initialValues.aso && (
											<FieldValuesSpy name="aso.motivoReprovado">
												{(motivoReprovado) => (
													<DocWrapper>
														<FieldGroup
															name="aso"
															label="Comprovante de escolaridade"
															forceErrorOnMount
															unsetForcedErrorOn={handleForcedErrorUnset}
														>
															<FieldWrapper>
																<FormWarning highlight>
																	<Text>
																		<strong>
																			{STATUS_DOCUMENTO[motivoReprovado]}
																		</strong>
																		<p>
																			Verifique abaixo seus dados e envie
																			novamente o arquivo do comprovante
																		</p>
																	</Text>
																</FormWarning>
															</FieldWrapper>

															<FieldWrapper>
																<AttachmentField
																	noDelete
																	forceErrorUntilChange={
																		STATUS_DOCUMENTO[motivoReprovado]
																	}
																	name="aso.idAnexo"
																	type="comprovante_escolaridade"
																	label="Comprovante de escolaridade"
																	helperText="Diploma ou declaração de matrícula"
																/>
															</FieldWrapper>
														</FieldGroup>
														<FormWarning center>
															{STATUS_DOCUMENTO[motivoReprovado]}
														</FormWarning>
													</DocWrapper>
												)}
											</FieldValuesSpy>
										)}
									</FormSection>
								)}
							</FormBody>

							<FormFooter>
								{submitError && <FormError error={submitError} />}

								<Button type="submit" primary large submitting={submitting}>
									Salvar
								</Button>
							</FormFooter>
						</FormWrapper>
					)}
				</Form>
			)}
		</MainLayout>
	)
}

export default DocReviewPage
