import styled, { css } from 'styled-components'

const formatMargin = (m) => {
	if (typeof m === 'number') return `${m}rem`
	if (typeof m === 'function') return m()

	return m
}

const LayoutBox = styled.div`
	${(p) => css`
		${p.m &&
		css`
			margin: ${formatMargin(p.m)};
		`}
		${p.mt &&
		css`
			margin-top: ${formatMargin(p.mt)};
		`}
  ${p.mr &&
		css`
			margin-right: ${formatMargin(p.mr)};
		`}
  ${p.mb &&
		css`
			margin-bottom: ${formatMargin(p.mb)};
		`}
  ${p.ml &&
		css`
			margin-left: ${formatMargin(p.ml)};
		`}
	`}
`

export default LayoutBox
