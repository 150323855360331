/* eslint-disable indent */
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

function TextInput({
	label,
	name,
	value,
	onChange,
	error,
	valid,
	EndIcon,
	className,
	...props
}) {
	const [focused, setFocused] = useState(false)
	// inputRef = inputRef ?? useRef(null)
	const inputRef = useRef(null)

	useEffect(() => {
		const input = inputRef.current
		const eventHandler = (e) => setFocused(e.type === 'focus')

		input.addEventListener('focus', eventHandler)
		input.addEventListener('blur', eventHandler)

		return () => {
			input.removeEventListener('focus', eventHandler)
			input.removeEventListener('blur', eventHandler)
		}
	}, [inputRef])

	return (
		<Wrapper
			focused={focused}
			error={error}
			valid={valid}
			disabled={props.disabled}
			className={className}
		>
			<Label>
				<Input
					type="text"
					value={value}
					onChange={onChange}
					ref={inputRef}
					{...props}
				/>
				{EndIcon && (
					<InputIcon>
						<EndIcon />
					</InputIcon>
				)}
				<LabelText>{label}</LabelText>
			</Label>
		</Wrapper>
	)
}

const Wrapper = styled.div.attrs((props) => ({
	className: clsx({
		error: props.error,
		disabled: props.disabled,
		focused: props.focused,
	}),
}))`
	background-color: #fff;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	/* box-shadow: 0 0 2px #0006; */
	font-size: 1.05rem;
	height: 3.465rem;
	min-width: 100%;
	transition: all 0.4s ease;
	position: relative;
	overflow: hidden;

	&.disabled {
		background: #f5f5f5;
	}

	&.focused {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: var(--input-line-thickness);
		transition: all 0.4s ease;

		${({ focused, error, valid, disabled }) =>
			disabled
				? css`
						background: #e6e6e6;
						transform: scaleY(0.6);
						transform-origin: bottom;
				  `
				: error
				? css`
						background: var(--color-error);
				  `
				: valid
				? css`
						background: var(--color-success);
				  `
				: focused
				? css`
						background: var(--gradient);
				  `
				: css`
						background: #b5b5b5;
				  `}
	}
`

const Label = styled.label`
	display: flex;
	height: 100%;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	transition: all 0.4s ease;

	${Wrapper}.error:not(.disabled) & {
		border: 1px solid var(--color-error);
	}
`

const Input = styled.input.attrs((props) => ({
	className: clsx('input', {
		empty: props.value?.length === 0,
	}),
	maxLength: props.maxLength ?? '64',
}))`
	border: 0;
	outline: none;
	width: 100%;
	height: 100%;
	transition: padding-top 0.3s ease;
	color: var(--color-text-light);
	font-weight: 400;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	padding: 0.7em;

	:focus,
	:not(.empty) {
		padding-top: 1.4em;
	}
	:-webkit-autofill {
		padding-top: 1.4em;
	}

	::placeholder {
		opacity: 0;
		transition: opacity 0.2s ease;
		color: var(--color-text-lighter);
	}
	:focus::placeholder {
		opacity: 1;
	}

	${Wrapper}.error & {
		padding-left: calc(0.7em - 1px);
	}
	${Wrapper}.disabled & {
		background: transparent;
	}
`

const LabelText = styled.div.attrs({
	className: 'labelText',
})`
	color: var(--color-text-dark);
	position: absolute;
	top: calc(50% + 1px);
	left: 0.7em;
	transform: translateY(-50%);
	font-weight: 400;
	pointer-events: none;
	transition: all 0.3s ease;
	transform-origin: left;
	max-width: calc(100% - 0.8em);
	text-overflow: ellipsis;
	overflow: hidden;

	${Wrapper}.disabled & {
		color: var(--color-text-light);
	}

	${Input}:focus ~ &, 
  ${Input}:not(.empty) ~ & {
		top: 0.37em;
		transform: scale(0.7);
		transform-origin: left;
		font-weight: 600;
		letter-spacing: 0.01em;
	}
	${Input}:-webkit-autofill ~ & {
		top: 0.37em;
		transform: scale(0.7);
		transform-origin: left;
		font-weight: 600;
		letter-spacing: 0.01em;
	}
`

const InputIcon = styled.div`
	display: flex;
	position: absolute;
	right: calc(var(--spacing) * 0.6);
	top: 50%;
	transform: translateY(-50%);
`

export default styled(TextInput)``
