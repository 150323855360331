/* eslint-disable no-shadow */
/* eslint-disable  */
import React from 'react'
import setFieldData from 'final-form-set-field-data'
import { Form } from 'react-final-form'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import { base64ToBlob } from 'base64-blob'
import { GoAlert } from 'react-icons/go'
import saveAs from 'file-saver'

import api from '~/services/api'
// import { path as complementaryPath } from '../complementaryPage'
import usePBVForm from '~/hooks/usePBVForm'
import MainLayout from '~/layouts/mainLayout'
import history from '~/helpers/history'
import {
	Button,
	FormBody,
	FormFooter,
	FormWrapper,
	Loading,
	Text,
	AttachmentField,
	FieldWrapper,
	FieldSection,
	FormInfo,
} from '~/components'

import dadosBancariosBradescoQRCode from './assets/dados-bancarios-bradesco-210801405.jpeg'

import ErrorPage from '../errorPage'
import { AlertBox } from './styles'

export const title = 'Encaminhamento bancário'
export const path = '/acoes-complementares/encaminhamento-bancario'

const attachmentFields = ['idAnexo']

const BankingInfoPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const { loading, error, initialValues, editingDisabled, submitForm } =
		usePBVForm(`dadosBancarios`, {
			attachmentFields,
		})

	const handleGoBack = () => history.goBack()

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	const downloadBankInfo = async () => {
		const toastId = toast.loading('Baixando...')

		try {
			const {
				data: { data },
			} = await api.dadosBancarios.download()
			saveAs(await base64ToBlob(data), 'encaminhamento-bancario.pdf')
			toast.success('Arquivo baixado', { id: toastId })
		} catch (err) {
			toast.error(err.message, { id: toastId })
		}
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)
	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{ submitting: true, pristine: true, values: true }}
				mutators={{ setFieldData }}
			>
				{({ handleSubmit, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark style={{ marginTop: '20px' }}>
								<span style={{ fontWeight: 'bold' }}>Opção 1) Abertura de conta pelo App</span>
								<ul>
									<li>Instalar o app do Bradesco no seu celular  <a target="_blank" href='https://play.google.com/store/apps/details?id=com.bradesco'>clicando neste link</a></li>
									<li>Abra sua conta</li>
									<li>Crie sua senha de 4 dígitos</li>
									<li>Tipo de conta: Clique na opção “Recebimento de Salário" e insira os números do convênio da sua empresa:</li>
								</ul>
								<p><span style={{ fontWeight: 'bold' }}>americanas sa: 130400657</span></p>
								<p><span style={{ fontWeight: 'bold' }}>CNPJ: 00.776.574/0001-56</span></p>

							</Text>

							<Text dark style={{ marginTop: '30px' }}>
								{editingDisabled ? (
									<FormInfo>Você já enviou seus dados bancários</FormInfo>
								) : (
									<div>
										<hr />
										<div style={{ marginTop: '20px' }}>
											<span style={{ fontWeight: 'bold' }}>Opção 2) Abertura de conta na agência bancária</span>
											<p>

												Baixe o encaminhamento bancário e leve até o banco indicado.
												Após a criação da sua conta, anexe aqui uma foto com os
												novos dados bancários.
											</p>
										</div>
									</div>
								)}
							</Text>

							{!editingDisabled && (
								<FieldWrapper>
									<Button large onClick={downloadBankInfo}>
										Baixar encaminhamento
									</Button>
								</FieldWrapper>
							)}

							<FieldSection label="Encaminhamento Bancário">
								<FieldWrapper>
									<AttachmentField
										name="idAnexo"
										type="dados_bancarios"
										label="Dados Bancários"
										disabled={editingDisabled}
									/>
								</FieldWrapper>
							</FieldSection>



							<AlertBox>
								<GoAlert
									size={24}
									color="EBC100"
									style={{ marginRight: '10px' }}
								/>
								<div>Não esqueça de salvar seu anexo no botão abaixo!</div>
							</AlertBox>
						</FormBody>

						<FormFooter>
							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? 'Voltar' : 'Salvar'}
							</Button>
						</FormFooter>

						{/* <img src={dadosBancariosBradescoQRCode} style={{ zIndex: 0 }} /> */}

					</FormWrapper>
				)}
			</Form>
		</MainLayout>
	)
}

export default BankingInfoPage
