/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useLocation } from 'react-router'
import { FORM_ERROR } from 'final-form'
import toast from 'react-hot-toast'
import queryString from 'query-string'

import {
	Button,
	Error,
	FieldWrapper,
	FormBody,
	FormError,
	FormFooter,
	FormWrapper,
	Loading,
	PassField,
	Text,
	Title,
} from '~/components'
import {
	minLength,
	mustHaveLower,
	mustHaveNumber,
	mustHaveSpecial,
	mustHaveUpper,
	required,
} from '~/helpers/validators'
import api from '~/services/api'
import handleReqError from '~/helpers/handleReqError'
import { delay } from '~/helpers/delay'
import { path as recoveryPath } from '~/pages/recoveryPage'
import { path as loginPath } from '~/pages/loginPage'

import { path as homePath } from '../homePage'
import { StyledLoginLayout, StyledReaptcha } from './styles'
import { SITEKEY } from '../../config/constants'

export const title = 'Redefinir senha'
export const path = '/redefinir-senha'
export const isPublic = true

const ResetPassPage = () => {
	const [user, setUser] = useState(null)
	const [tokenError, setTokenError] = useState(null)

	const { search } = useLocation()

	const captcha = useRef(null)
	const [recaptchaResponseToken, setRecaptchaResponseToken] = useState(null)

	useEffect(() => {
		const { t: token } = queryString.parse(search)
		const cancelSource = api.getCancelSource()

		if (!token) {
			setTokenError('no-token')
			return
		}

		;(async () => {
			try {
				await delay(20)
				const response = await api.accessLink.validate({ token, cancelSource })

				setUser({
					token,
					primeiroAcesso: response.data?.primeiroAcesso,
					nome: response.data?.nome,
				})
			} catch (err) {
				setTokenError(
					handleReqError(err, {
						401: 'token-expired',
						410: 'token-expired',
					}),
				)
			}
		})()

		return () => cancelSource.cancel()
	}, [search])

	const handleSubmit = async (values) => {
		try {
			const response = await api.password.save({
				values,
				token: user.token,
				g_recaptcha_response: recaptchaResponseToken,
			})

			toast.success('Senha alterada! Aguarde...', { duration: 2000 })
			setTimeout(() => {
				window.location = homePath
			}, 1500)

			return response
		} catch (err) {
			const error = handleReqError(err, {
				401: () => setTokenError('token-expired'),
				406: 'Senha não atende os requisitos',
				409: 'Senha não pode ser a mesma que a anterior',
			})

			return {
				[FORM_ERROR]: error,
			}
		}
	}

	// Recaptcha

	const onLoadRecaptcha = () => {
		if (captcha.current) {
			captcha.current.renderExplicitly()
		}
	}

	if (!user && !tokenError) {
		return <Loading />
	}

	const sitekey = SITEKEY.join('-')

	return (
		<StyledLoginLayout tokenError={tokenError}>
			{tokenError ? (
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						marginTop: '1rem',
					}}
				>
					<div style={{ padding: '0 var(--spacing)', flexGrow: 1 }}>
						<Error />
					</div>
					<div
						style={{
							padding: '0 var(--spacing)',
							marginBottom: 'calc(1 * var(--spacing))',
							textAlign: 'center',
						}}
					>
						{tokenError === 'no-token' || tokenError === 'token-expired' ? (
							<div>Este link não está mais válido :(</div>
						) : (
							<FormError error={tokenError} />
						)}
					</div>
					<div>
						<Button primary large to={recoveryPath}>
							Reenviar e-mail de recuperação
						</Button>
						<Button large to={loginPath}>
							Ir para a tela de login
						</Button>
					</div>
				</div>
			) : (
				<Form
					onSubmit={handleSubmit}
					subscription={{ submitting: true, pristine: true, submitError: true }}
					validate={(values) => {
						const errors = {}
						if (values.pass !== values.confirmPass)
							errors.confirmPass = 'Senhas não estão iguais'

						return errors
					}}
				>
					{({ handleSubmit, submitError, submitting }) => (
						<FormWrapper onSubmit={handleSubmit}>
							<FormBody style={{ padding: '0 1.25rem' }}>
								<Text style={{ marginBottom: '2rem' }}>
									<Title>Olá{user?.nome ? `, ${user?.nome}` : ''}!</Title>
									{user?.primeiroAcesso ? (
										<p>
											Como esse é o seu primeiro acesso, será necessário criar
											uma senha, que <strong>obrigatoriamente</strong> deverá
											conter:
										</p>
									) : (
										<p>
											Sua nova senha <strong>obrigatoriamente</strong> deverá
											conter:
										</p>
									)}

									<FormSpy subscription={{ values: true }}>
										{({ values }) => (
											<ul className="validation">
												<li
													className={
														!mustHaveUpper(values.pass) ? 'check' : undefined
													}
												>
													Letras <strong>maiúsculas</strong>;
												</li>
												<li
													className={
														!mustHaveLower(values.pass) ? 'check' : undefined
													}
												>
													Letras <strong>minúsculas</strong>;
												</li>
												<li
													className={
														values.pass && !minLength(8)(values.pass)
															? 'check'
															: undefined
													}
												>
													Mínimo de <strong>8 caracteres</strong>;
												</li>
												<li
													className={
														!mustHaveNumber(values.pass) ? 'check' : undefined
													}
												>
													<strong>Números</strong>;
												</li>
												<li
													className={
														!mustHaveSpecial(values.pass) ? 'check' : undefined
													}
												>
													<strong>Caracteres especiais</strong>.
												</li>
											</ul>
										)}
									</FormSpy>
								</Text>

								<FieldWrapper>
									<PassField
										newPass
										name="pass"
										label="Senha"
										validators={[required]}
									/>
								</FieldWrapper>
								<FieldWrapper>
									<PassField
										name="confirmPass"
										label="Repita a senha"
										validators={[required]}
										autoComplete="new-password"
									/>
								</FieldWrapper>
							</FormBody>

							<FormFooter>
								<StyledReaptcha
									ref={(ref) => (captcha.current = ref)}
									sitekey={sitekey}
									hl="pt-BR"
									onLoad={onLoadRecaptcha}
									onVerify={(recaptchaToken) =>
										setRecaptchaResponseToken(recaptchaToken)
									}
									onExpire={() => setRecaptchaResponseToken(null)}
									explicit
								/>

								{submitError && <FormError error={submitError} />}

								<Button
									type="submit"
									primary
									large
									submitting={submitting}
									disabled={!recaptchaResponseToken}
								>
									Salvar senha
								</Button>
							</FormFooter>
						</FormWrapper>
					)}
				</Form>
			)}
		</StyledLoginLayout>
	)
}

export default ResetPassPage
