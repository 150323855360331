/* eslint-disable no-shadow */
import React from 'react'
import setFieldData from 'final-form-set-field-data'
import { Form } from 'react-final-form'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import { base64ToBlob } from 'base64-blob'
import saveAs from 'file-saver'
import { GoAlert } from 'react-icons/go'

import api from '~/services/api'
// import { path as complementaryPath } from '../complementaryPage'
import usePBVForm from '~/hooks/usePBVForm'
import MainLayout from '~/layouts/mainLayout'
import history from '~/helpers/history'
import {
	Button,
	FormBody,
	FormFooter,
	FormWrapper,
	Loading,
	Text,
	AttachmentField,
	FieldWrapper,
	FieldSection,
	FormInfo,
} from '~/components'

import ErrorPage from '../errorPage'
import { AlertBox } from './styles'

export const title = 'Exame ocupacional'
export const path = '/acoes-complementares/exame-ocupacional'

const attachmentFields = ['idAnexo']

const AsoPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const { loading, error, initialValues, editingDisabled, submitForm } =
		usePBVForm(`aso`, {
			attachmentFields,
		})

	const handleGoBack = () => history.goBack()

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	const downloadASO = async () => {
		const toastId = toast.loading('Baixando...')

		try {
			const {
				data: { data },
			} = await api.aso.download()
			saveAs(await base64ToBlob(data), 'exame-clinico-ocupacional.pdf')
			toast.success('Arquivo baixado', { id: toastId })
		} catch (err) {
			toast.error(err.message, { id: toastId })
		}
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)

	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{ submitting: true, pristine: true, values: true }}
				mutators={{ setFieldData }}
			>
				{({ handleSubmit, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>Você já enviou seu exame ocupacional</FormInfo>
								) : (
									<p>
										Baixe o encaminhamento do exame. Após a realização, anexe
										aqui a guia com os dados do exame.
									</p>
								)}
							</Text>

							{!editingDisabled && (
								<FieldWrapper>
									<Button large onClick={downloadASO}>
										Baixar encaminhamento
									</Button>
								</FieldWrapper>
							)}

							<FieldSection label="Exame Clínico Ocupacional">
								<FieldWrapper>
									<AttachmentField
										name="idAnexo"
										type="aso"
										label="Foto da guia preenchida"
										disabled={editingDisabled}
									/>
								</FieldWrapper>
							</FieldSection>

							<AlertBox>
								<GoAlert
									size={24}
									color="EBC100"
									style={{ marginRight: '10px' }}
								/>
								<div>Não esqueça de salvar seu anexo no botão abaixo!</div>
							</AlertBox>
						</FormBody>

						<FormFooter>
							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? 'Voltar' : 'Salvar'}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>
		</MainLayout>
	)
}

export default AsoPage
