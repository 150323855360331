/* eslint-disable no-shadow */
import createDecorator from 'final-form-calculate'
import setFieldData from 'final-form-set-field-data'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
	AttachmentField,
	Button,
	EmailField,
	FieldGroup,
	FieldValuesSpy,
	FieldWrapper,
	FormBody,
	FormError,
	FormFooter,
	FormInfo,
	FormIsntComplete,
	FormRow,
	FormSection,
	FormWrapper,
	Loading,
	PhoneField,
	SelectField,
	Text,
	TextField,
} from '~/components'
import { CITIES_BY_UF, COUNTRIES, UFS } from '~/config/constants'
import { formatCEP } from '~/helpers/formatters'
import handleReqError from '~/helpers/handleReqError'
import history from '~/helpers/history'
import { cep, checkErrorValue } from '~/helpers/validators'
import usePBVForm from '~/hooks/usePBVForm'
import MainLayout from '~/layouts/mainLayout'
import api from '~/services/api'
import IconSearch from '~/svgs/iconSearch'
// import { path as registrationPath } from '../registrationPage'
import ErrorPage from '../errorPage'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'

export const title = 'Endereço e contato'
export const path = '/dados-cadastrais/enderecos-contatos'

// decorator para chamar a api do cep quando o cep for preenchido
const cepHandler = (form) =>
	createDecorator({
		field: 'cep',
		updates: async (value, name, allValues) => {
			if (!form.getFieldState('cep')?.modified || /^\$error/.test(value))
				return {}

			if (!value?.length || value?.length !== 8 || allValues.pais !== 'BR')
				return {}

			let fields = {}

			try {
				const { data } = await api.cep({ value })

				// se algum dos valores anteriores de endereço for diferente do valor obtido pelo cep, zerar campos 'número' e 'complemento'
				if (
					allValues.logradouro !== data.logradouro ||
					allValues.bairro !== data.bairro ||
					allValues.cidade !== data.cidade ||
					allValues.estado !== data.uf
				) {
					fields = {
						...fields,
						numero: undefined,
						complemento: undefined,
					}
				}

				fields = {
					...fields,
					logradouro: data.logradouro,
					bairro: data.bairro,
					cidade: data.cidade,
					estado: data.uf,
				}

				return fields
			} catch (err) {
				return {
					cep: handleReqError(err, {
						404: `$error=CEP não foi localizado&value=${value}`,
						default: () => {
							return undefined
						},
					}),
				}
			}
		},
	})(form)

const attachmentFields = ['comprovanteResidencia.idAnexo']

const defaultValues = {
	pais: 'BR',
	comprovanteResidencia: {},
}

const AddressContactPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const [city, setCity] = useState('')

	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm('enderecoContato', {
			attachmentFields,
			defaultValues,
		})

	const handleGoBack = () => history.goBack()

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				setCity(values.cidade)
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)

	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
				}}
				decorators={[cepHandler]}
				mutators={{ setFieldData }}
			>
				{({ handleSubmit, submitError, submitting, form }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>
										Você já enviou seus dados de endereço e contato e neste
										momento do processo eles não podem ser alterados
									</FormInfo>
								) : (
									<p>
										Preencha seus contatos atentamente, nossa interação é
										totalmente online. Estes dados poderão ser usados para
										contatar você.
									</p>
								)}
							</Text>

							{/* ----- ENDEREÇO ----- */}
							<FormSection label="Endereço">
								{/* PAÍS */}
								<FieldWrapper>
									<SelectField
										name="pais"
										label="País"
										showSearchByDefault
										options={COUNTRIES}
										autoComplete="section-address country"
										disabled={editingDisabled}
									/>
								</FieldWrapper>

								<FieldValuesSpy names={['pais', 'estado', 'cidade']}>
									{({ pais, estado, cidade }) => {
										const isBrazil = pais === 'BR' || !pais // true se pais for brasil ou undefined

										return (
											<>
												{/* CEP */}
												<FieldWrapper>
													<TextField
														name="cep"
														label={isBrazil ? 'CEP' : 'Código Postal'}
														validators={[checkErrorValue, cep]}
														formatter={formatCEP}
														parser={(value) =>
															value.replace(/\D/g, '').slice(0, 8)
														}
														EndIcon={IconSearch}
														autoComplete="section-address postal-code"
														disabled={editingDisabled}
													/>
												</FieldWrapper>

												{isDesktop ? (
													<FormRow
														template="70px 1fr 1fr"
														disableTemplate={!isBrazil}
													>
														{/* UF */}
														<FieldWrapper>
															{isBrazil ? (
																<SelectField
																	name="estado"
																	label="UF"
																	value={
																		!UFS.includes(estado)
																			? form.change('estado', '')
																			: estado
																	}
																	options={UFS}
																	autoComplete="section-address address-level1"
																	disabled={editingDisabled}
																/>
															) : (
																<TextField
																	name="estado"
																	label="Estado/Distrito/Província"
																	autoComplete="section-address address-level1"
																	disabled={editingDisabled}
																/>
															)}
														</FieldWrapper>

														{/* MUNICÍPIO */}
														<FieldWrapper>
															{isBrazil ? (
																<SelectField
																	showSearchByDefault
																	name="cidade"
																	label="Município"
																	value={cidade || city}
																	options={CITIES_BY_UF[estado]}
																	autoComplete="section-address address-level2"
																	disabled={
																		!pais ||
																		!estado ||
																		!UFS.includes(estado) ||
																		editingDisabled
																	}
																/>
															) : (
																<TextField
																	name="cidade"
																	label="Cidade/Município"
																	autoComplete="section-address address-level2"
																	disabled={editingDisabled}
																/>
															)}
														</FieldWrapper>

														{/* BAIRRO */}
														<FieldWrapper>
															<TextField
																name="bairro"
																label="Bairro"
																maxLength="30"
																autoComplete="section-address address-level3"
																disabled={editingDisabled}
															/>
														</FieldWrapper>
													</FormRow>
												) : (
													<>
														<FormRow
															template={[0.24, 'auto']}
															disableTemplate={!isBrazil}
														>
															{/* UF */}
															<FieldWrapper>
																{isBrazil ? (
																	<SelectField
																		name="estado"
																		label="UF"
																		options={UFS}
																		autoComplete="section-address address-level1"
																		disabled={editingDisabled}
																	/>
																) : (
																	<TextField
																		name="estado"
																		label="Estado/Distrito/Província"
																		autoComplete="section-address address-level1"
																		disabled={editingDisabled}
																	/>
																)}
															</FieldWrapper>

															{/* MUNICÍPIO */}
															<FieldWrapper>
																{isBrazil ? (
																	<SelectField
																		showSearchByDefault
																		name="cidade"
																		label="Município"
																		options={CITIES_BY_UF[estado]}
																		autoComplete="section-address address-level2"
																		disabled={
																			!pais || !estado || editingDisabled
																		}
																	/>
																) : (
																	<TextField
																		name="cidade"
																		label="Cidade/Município"
																		autoComplete="section-address address-level2"
																		disabled={editingDisabled}
																	/>
																)}
															</FieldWrapper>
														</FormRow>

														{/* BAIRRO */}
														<FieldWrapper>
															<TextField
																name="bairro"
																label="Bairro"
																maxLength="30"
																autoComplete="section-address address-level3"
																disabled={editingDisabled}
															/>
														</FieldWrapper>
													</>
												)}
											</>
										)
									}}
								</FieldValuesSpy>

								{/* LOGRADOURO */}
								<FieldWrapper>
									<TextField
										name="logradouro"
										label="Logradouro"
										maxLength="100"
										autoComplete="section-address address-line1"
										disabled={editingDisabled}
									/>
								</FieldWrapper>

								<FormRow template={[0.24, 'auto']}>
									{/* NÚMERO */}
									<FieldWrapper>
										<TextField
											name="numero"
											label="Nº"
											maxLength="10"
											autoComplete="section-address street-number"
											disabled={editingDisabled}
										/>
									</FieldWrapper>

									{/* COMPLEMENTO */}
									<FieldWrapper>
										<TextField
											name="complemento"
											label="Complemento"
											maxLength="10"
											autoComplete="section-address address-line2"
											disabled={editingDisabled}
										/>
									</FieldWrapper>
								</FormRow>

								{/* COMPROVANTE DE RESIDÊNCIA */}
								<FieldWrapper>
									<FieldGroup
										name="comprovanteResidencia"
										label="Comprovante de residência"
									>
										<FieldWrapper>
											<AttachmentField
												name="comprovanteResidencia.idAnexo"
												type="comprovante_residencia"
												label="Foto do Comprovante de residência"
												disabled={editingDisabled}
											/>
										</FieldWrapper>
									</FieldGroup>
								</FieldWrapper>
							</FormSection>

							{/* ----- CONTATO ----- */}
							<FormSection label="Contato">
								<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
									{/* CELULAR */}
									<FieldWrapper>
										<PhoneField
											mobile
											name="celular"
											label="Celular"
											disabled
										/>
									</FieldWrapper>

									{/* TELEFONE SECUNDARIO */}
									<FieldWrapper>
										<PhoneField
											name="telefoneSecundario"
											label="Telefone secundário"
											helperText="Opcional"
											disabled={editingDisabled}
										/>
									</FieldWrapper>

									{/* EMAIL */}
									<FieldWrapper>
										<EmailField name="email" label="E-mail" disabled />
									</FieldWrapper>

									{/* EMAIL SECUNDARIO */}
									<FieldWrapper>
										<EmailField
											name="emailSecundario"
											label="E-mail secundário"
											helperText="Opcional"
											disabled={editingDisabled}
										/>
									</FieldWrapper>
								</FormRow>
							</FormSection>
						</FormBody>

						<FormFooter>
							{submitError && <FormError error={submitError} />}

							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? (
									'Voltar'
								) : (
									<>
										Salvar
										<FormIsntComplete> e continuar depois</FormIsntComplete>
									</>
								)}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>
		</MainLayout>
	)
}

export default AddressContactPage
