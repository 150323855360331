import React from 'react'
import clsx from 'clsx'
import styled, { keyframes } from 'styled-components'
import { mq } from '~/helpers/breakpoints'
import Link from './link'

function Button({ children, Icon, iconProps, ...props }) {
	if (props.as === 'input') return <Wrapper {...props} />

	return (
		<Wrapper {...props}>
			{Icon ? (
				<Icon
					size={1.3}
					style={{ marginRight: 'calc(.3 * var(--spacing))' }}
					{...iconProps}
				/>
			) : undefined}
			<Label>{children}</Label>
		</Wrapper>
	)
}

const Wrapper = styled.button
	.withConfig({
		shouldForwardProp: (prop, defaultValidatorFn) =>
			['to'].includes(prop) || defaultValidatorFn(prop),
	})
	.attrs((props) => ({
		type:
			props.to || (props.as && props.as !== 'input' && props.as !== 'button')
				? undefined
				: props.type || 'button',
		className: clsx({
			primary: props.primary,
			large: props.large,
			inline: props.inline,
			active: props.active,
			disabled: props.disabled,
			submitting: props.submitting,
		}),
		disabled: props.disabled || props.active || props.submitting,
	}))`
  font-size: 1rem;
  height: 2.9em;
  min-width: 5em;
  background-color: #fff;
  color: #000;
  border: 1px solid black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  box-shadow: 0 0 5px #0006;
  padding: ${({ primary }) =>
		primary ? '.6em calc(0.9em + 1px)' : '.6em 0.9em'};
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  position: relative;

  transition: all 0.2s ease;

  -webkit-tap-highlight-color: var(--color-tap);
  user-select: none;
  outline: none;


  :hover, :focus {
    color: #000;
  }

  &:not(.disabled):not(.active) {
    box-shadow: 0 0 5px rgba(0,0,0,.35);
    :hover {
      box-shadow: 0 0 9px rgba(0,0,0,.5);
    }
    :focus {
      box-shadow: 0 0 10px rgba(0,0,0,.65);
    }
    :active {
      box-shadow: 0 0 3px rgba(0,0,0,.3);
    }
  }
  
  &.primary {
    background-color: #000;
    color: #fff;
    border: none;
    :hover, :focus {
      color: #fff;
    }
  }

  &.large {
    width: calc(100% - calc(2 * var(--spacing)));
    margin: 0 var(--spacing);

    ${mq.min('sm')} {
      width: 15em;
      margin: 0 auto;
      display: flex;
    }

    & + & {
      margin-top: var(--spacing);
    }
  }

  &.inline {
    display: inline-flex;
    margin: 0;
  }

  &.disabled {
    cursor: default;
    &:not(.primary) {
      border: 1px solid #777;
      background: #f9f9f9;
      color: #939393;
      :hover, :focus {
        color: #939393;
      }
    }
    
    &.primary {
      background: #b5b5b5;
      color: #707070;
      :hover, :focus {
        color: #707070;
      }
    }
  }


  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background-image: var(--gradient);
  }

  &.disabled::after {
    filter: contrast(0.5);
  }

  

  &.submitting {
    cursor: default;
    background: #868686;
    color: #404040;
    :hover, :focus {
      color: #404040;
    }

    ::after {
      background-image: var(--gradient-symetric);
      background-size: 200%;
      background-position-x: 0;
      animation: ${keyframes`
        from {
          background-position-x: 0;
        }
        to {
          background-position-x: -200%;
        }
      `} 1s linear infinite;
    }
  }
`

const Label = styled.span`
	font-size: 0.9em;
	font-weight: 600;
`

export default styled(Button).attrs((p) => ({
	forwardedAs: p.to ? Link : p.renderAs ? p.renderAs : undefined,
}))``
