/* eslint-disable no-plusplus */
/* eslint-disable indent */
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'
import isBefore from 'date-fns/isBefore'
import { differenceInYears, isAfter } from 'date-fns'
import _ from 'lodash'

export const composeValidators =
	(...validators) =>
	(value) =>
		validators.reduce(
			(error, validator) => error || validator(value),
			undefined,
		)

export const required = (value) => (value ? undefined : 'Campo obrigatório')

export const nameMustBeComplete = (value) =>
	!value || (value && value.trim().split(' ').length >= 2)
		? undefined
		: 'Deve ser o nome completo'

export const number = (value) =>
	value && _.isNaN(value) ? 'Deve ser um número' : undefined

export const minValue = (min) => (value) =>
	_.isNaN(value) || value >= min ? undefined : `Deve ser maior que ${min}`

export const minLength = (length) => (value) =>
	!value
		? undefined
		: value?.length >= length
		? undefined
		: `Deve possuir ${length} ou mais caracteres`

export const mustHaveUpper = (value) =>
	/[A-Z]/.test(value ?? '') ? undefined : 'Deve possuir letras maiúsculas'

export const mustHaveLower = (value) =>
	/[a-z]/.test(value ?? '') ? undefined : 'Deve possuir letras minúsculas'

export const mustHaveNumber = (value) =>
	/\d/.test(value ?? '') ? undefined : 'Deve possuir números'

export const mustString = (value) =>
	/^[A-Za-z]*$/.test(value ?? '')
		? undefined
		: 'Não deve possuir números ou caracteres especiais'

export const mustHaveSpecial = (value) =>
	/[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]+/.test(value ?? '')
		? undefined
		: 'Deve possuir caracteres especiais'

export const email = (value) =>
	!value ||
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:)\])/.test(
		value,
	)
		? undefined
		: 'Deve ser um email'

export const cpf = (cpfNumber) => {
	if (!cpfNumber) return undefined

	const error = 'Deve ser um CPF válido'

	cpfNumber = String(cpfNumber)

	if (!/^[0-9]{11}$/g.test(cpfNumber)) return error

	if (
		[
			'00000000000',
			'11111111111',
			'22222222222',
			'33333333333',
			'44444444444',
			'55555555555',
			'66666666666',
			'77777777777',
			'88888888888',
			'99999999999',
		].includes(cpfNumber)
	)
		return error

	let sum
	let remainder
	sum = 0

	for (let i = 1; i <= 9; i++)
		sum += parseInt(cpfNumber.substring(i - 1, i)) * (11 - i)
	remainder = (sum * 10) % 11

	if (remainder === 10 || remainder === 11) remainder = 0
	if (remainder !== parseInt(cpfNumber.substring(9, 10))) return error

	sum = 0
	for (let i = 1; i <= 10; i++)
		sum += parseInt(cpfNumber.substring(i - 1, i)) * (12 - i)
	remainder = (sum * 10) % 11

	if (remainder === 10 || remainder === 11) remainder = 0
	if (remainder !== parseInt(cpfNumber.substring(10, 11))) return error

	return undefined
}

export const emailOrCpf = (value) => {
	if (!cpf(value) || !email(value)) return undefined

	return 'Deve ser um e-mail ou CPF'
}

export const phone = (phoneNumber) => {
	if (!phoneNumber) return undefined

	phoneNumber = phoneNumber.replace(/\D/g, '')

	const match = phoneNumber.match(/^(\d{2})\d{8,9}$/)

	if (!match) return 'Deve ser um telefone válido'

	const dddList = [
		11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
		37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64,
		65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
		89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
	]

	if (!dddList.includes(Number(match[1]))) return 'O DDD digitado não é válido'

	return undefined
}

export const cellphone = (cellPhoneNumber) => {
	if (!cellPhoneNumber) return undefined

	cellPhoneNumber = cellPhoneNumber.replace(/\D/g, '')

	const match = cellPhoneNumber.match(/^(\d{2})(\d)\d{8}$/)

	if (!match) return 'Deve ser um celular válido'

	const dddList = [
		11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
		37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64,
		65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
		89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
	]

	if (!dddList.includes(Number(match[1]))) return 'O DDD digitado não é válido'

	// if(Number(match[2]) !== '9')
	//   return "Nono dígito deve ser 9"

	return undefined
}

export const pis = (pisValue) => {
	if (!pisValue) return undefined

	pisValue = pisValue.replace(/\D/g, '')
	const error = 'Deve ser um PIS válido'

	const base = '3298765432'
	let sum = 0
	const lastDigit = parseInt(pisValue[10])
	let remainder

	for (let i = 0; i < 10; i++) {
		sum += parseInt(pisValue[i]) * parseInt(base[i])
	}
	remainder = 11 - (sum % 11)
	remainder = remainder === 10 || remainder === 11 ? 0 : remainder

	if (lastDigit !== remainder) return error

	return undefined
}

export const date =
	(pattern = 'dd/MM/yyyy') =>
	(dateValue) => {
		if (!dateValue) return undefined

		const parsedDate = parse(dateValue, pattern, new Date())

		if (!(dateValue.length === pattern.length && isValid(parsedDate)))
			return 'Deve ser uma data válida'

		return undefined
	}

// atenção: se a data passada não for válida, este validador não retorna o erro. Deve ser usado em conjunto com o "date"
export const dateBefore =
	(pattern = 'dd/MM/yyyy') =>
	(dateValue) => {
		if (!dateValue) return undefined

		const parsedDate = parse(dateValue, pattern, new Date())

		if (isValid(parsedDate) && !isBefore(parsedDate, new Date()))
			return 'Deve ser uma data anterior'

		return undefined
	}

// atenção: se a data passada não for válida, este validador não retorna o erro. Deve ser usado em conjunto com o "date"
export const dateAfter =
	(pattern = 'dd/MM/yyyy') =>
	(dateValue) => {
		if (!dateValue) return undefined

		const parsedDate = parse(dateValue, pattern, new Date())

		if (isValid(parsedDate) && !isAfter(parsedDate, new Date()))
			return 'Deve ser uma data futura'

		return undefined
	}

export const dateMaxAge = (age) => (dateVal) => {
	if (!dateVal) return undefined

	const parsedDate = parse(dateVal, 'dd/MM/yyyy', new Date())

	if (differenceInYears(new Date(), parsedDate) >= age)
		return `Alguém nascido nesta data possui mais de ${age} anos`

	return undefined
}

export const cep = (cepValue) => {
	if (!cepValue) return undefined

	if (cepValue.replace(/\D/g, '').length !== 8) return 'Deve ser um CEP válido'

	return undefined
}

export const maxFileSize = (sizeInMB) => (value) => {
	if (!value) return undefined

	if (value?.file?.size && value.file.size > sizeInMB * 1000000)
		return `Deve ter menos de ${sizeInMB}MB`

	return undefined
}

export const imagesOnly = (value) => {
	if (!value || !value?.file) return undefined

	if (value?.file?.type && !/^image\//.test(value.file.type))
		return `O arquivo deve ser uma imagem`

	return undefined
}

export const attachmentExists = (value) => {
	if (!value) return undefined

	if (!value?.uuid) return `Arquivo não foi devidamente anexado`

	return undefined
}

export const attachmentStillSending = (value) => {
	if (!value) return undefined

	if (value?.sending) return `Arquivo está sendo enviado. Aguarde`

	return undefined
}

// deve ser usado em conjunto com um formatter para não mostrar o valor no caso de "$error=..."
export const checkErrorValue = (value) => {
	if (!value) return undefined

	if (typeof value === 'string' && /^\$error=/.test(value)) {
		return {
			showUntouched: true,
			message: value.split('&')[0].split('$error=').join(''),
		}
	}

	return undefined
}
