import clsx from 'clsx'
import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router'
import { stages } from '~/config/menu'
import { mq } from '~/helpers/breakpoints'
import { getStages } from '~/store/reducers/stages/selectors'
import { path as faqPath } from '~/pages/faqPage'
import { Loading, Link, Svg } from '~/components'
import StageLink from '~/components/stage/stageLink'
import Button from '~/components/button'
import { getUser } from '~/store/reducers/user/selectors'

const SideMenu = ({ ...props }) => {
	const wrapperRef = useRef(null)
	const { loading, currentStages } = useSelector(getStages)
	const { pathname } = useLocation()
	// const readyToFinish = currentStages?.reduce((ready, s) => ready===false? false : s.porcentagem === 1, undefined)
	const { revisaoGeralFinalizada } = useSelector(getUser)
	const readyToFinish =
		currentStages?.length > 1 &&
		currentStages?.reduce(
			(ready, s) => (ready === false ? false : s.porcentagem === 1),
			undefined,
		)

	return (
		<Wrapper ref={wrapperRef} {...props}>
			<StageList isLoading={loading}>
				{loading ? (
					<Loading />
				) : (
					<>
						<Menu>
							{currentStages?.map((cs) => {
								const Icon = stages.filter((s) => s.path === cs.rota)?.[0]?.icon
								const current = pathname === cs.rota
								return (
									<React.Fragment key={cs.rota}>
										<StyledStageLink
											name={cs.nome}
											Icon={Icon}
											error={cs.aprovado === false}
											percent={cs.porcentagem}
											enabled={cs.habilitado}
											current={current}
											to={cs.rota}
										/>
										{(cs.ativo || current) && cs?.subEtapa && (
											<SubMenu>
												{cs?.subEtapa?.map((se) => (
													<StyledStageLink
														key={se.id}
														name={se.nome}
														percent={se.porcentagem}
														enabled={se.habilitado}
														current={pathname === se.rota}
														to={se.rota}
													/>
												))}
											</SubMenu>
										)}
									</React.Fragment>
								)
							})}
						</Menu>

						{!revisaoGeralFinalizada && (
							<Button to="/revisao" large primary disabled={!readyToFinish}>
								Revisar para enviar
							</Button>
						)}

						<Instruction>
							<div>Após completar o preenchimento, aguarde a próxima fase.</div>
							<Link to={faqPath}>Saiba mais</Link>
						</Instruction>
					</>
				)}
			</StageList>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	grid-area: sidemenu;
	display: none;

	${mq.min('md')} {
		--side-menu-width: 250px;
		display: block;

		width: var(--side-menu-width);
		padding-right: 5px;

		${mq.min('87.5em')} {
			--side-menu-width: 300px;
		}

		overflow: hidden;
		transition: width 0.3s ease;
	}
`

const StageList = styled.div`
	height: 100%;
	border-right: 1px solid #dadada;
	display: flex;
	flex-direction: column;
	position: relative;

	${({ isLoading }) =>
		isLoading &&
		css`
			align-items: center;
			justify-content: center;
		`}

	${Button} {
		margin-top: 50px;
	}
`

const Menu = styled.div`
	background: #fff;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
	margin-right: -1px;
	border-bottom-right-radius: 1px;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: calc(100vh - var(--header-height) - var(--spacing) * 6.6);
`

const SubMenu = styled.div`
	margin-left: calc(var(--spacing) * 3);
	display: none;
	flex-direction: column;

	@media (min-height: 100px) {
		display: flex;
	}
`

const StyledStageLink = styled(StageLink).attrs((props) => ({
	className: clsx({
		current: props.current,
	}),
	to: !props.current && props.to,
	disabled: props.current,
}))`
	font-size: 1rem;
	height: 3.465rem;

	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: var(--spacing);
	border: 0;
	border-radius: 0;
	position: relative;
	font-weight: 400;
	transition: background-color 0.2s ease, color 0.2s ease;
	padding-right: calc(var(--spacing) * 2.75);
	white-space: nowrap;

	&.enabled {
		:hover,
		:focus-visible {
			border: 0;
			box-shadow: none;
			background-color: #fafafa;
		}
	}

	& + &,
	${SubMenu} + & {
		&,
		&.enabled {
			border-top: 1px solid #dadada;
		}
	}

	${Svg} {
		font-size: 1.7em;
		width: 1em;
		height: 1em;
		min-width: 1em;
	}

	.status-icon {
		left: auto;
		right: 0.875rem;

		&:not(.done) {
			box-shadow: 0 0 3px 0 rgb(0, 0, 0, 15%);
			transform: translateY(calc(-50% + 1px));
			border-radius: 50%;
		}
	}

	& > .name {
		max-width: calc(var(--side-menu-width) - var(--spacing) * 6.6);
		overflow: hidden;
		text-overflow: ellipsis;
	}

	/* current */

	&.current {
		font-weight: 600;
		background: #fff;
		color: var(--color-text-dark);

		::before {
			content: '';
			width: 4px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: var(--gradient-top-bottom);
		}
	}

	&.current + ${SubMenu} {
		display: flex;
	}

	${SubMenu} & + & {
		border-top: 0;
	}

	${SubMenu} & {
		margin-left: calc(-3 * var(--spacing));
		padding-left: calc((3 + 1.15) * var(--spacing));

		padding-top: calc(var(--spacing) * 0.6 + 1px);
		::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: calc(3 * var(--spacing));
			height: 1px;
			background-color: #dadada;
		}

		&.current {
			background: #f5f5f5;

			::before {
				display: none;
			}
		}
	}
`

const Instruction = styled.div`
	color: #959595;
	position: absolute;
	bottom: calc(var(--spacing) * 1);
	left: var(--spacing);
	right: var(--spacing);
	text-align: center;
	font-size: 0.9rem;
	line-height: 1.4;
	${Link} {
		font-size: 1rem;
		margin-top: 0.6rem;
		float: none;
	}
`

export default SideMenu
